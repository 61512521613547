import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

//系统标题
document.title = '商户管理系统'

//导入规则
import inc_routes from '@/router/routes'

//路由配置
const routes: Array<RouteRecordRaw> = [
    { path: '/', name: 'Index', redirect: '/system/welcome', component: () => import('@/views/index.vue'), children: []},
	{ path: '/system/404', name: 'System404', component: () => import('@/components/404.vue')},
	{ path: '/system/login', name: 'SystemLogin', component: () => import('@/views/system/login.vue')}
]

//添加路由
let pushRoute = function (root, list){
    for(let name in list){
        if(!root.children) root.children = [];
		
        if(list[name].children) {
            pushRoute(root, list[name].children);
        }else {
			if(!list[name].path) continue
            root.children.push(
                {
                    path: list[name].path,
                    name: list[name].name,
					query: list[name].query,
                    isTab: true,
                    meta: { title: list[name].title, isTab: true},
                    component: () => import('@/views' + list[name].path + '.vue')
                }
            );
        }
    }
}
pushRoute(routes[0], inc_routes);

//创建路由
const router = createRouter({
	history: createWebHashHistory(),
	routes
})

//登录验证
router.beforeEach((to, from, next) => {
	//如果是登录页面路径，就直接next()
	if (to.path === '/system/login') next()
	//其他页面路径
	else if(!window.vueApp.config.globalProperties._user.accessToken || !window.vueApp.config.globalProperties._user.userToken){
		console.log( to )
		next({ path: '/system/login' }) // 则跳转到登录页
	}else next()
})

export default router
