
import { ElMessageBox, ElMessage } from 'element-plus'


Object.defineProperty(Object.prototype, 'getOptionLabel', {
	configurable: true,
	enumerable: false,
	writable: true,
	value: function(ids, cutStr, joinStr) {
		if(ids === undefined) return ''
		if(!joinStr) joinStr = ','
		let label = ''
		if(cutStr) 
			ids = ids.split(cutStr)
		else 
			ids = [ids]
		for(let i=0,max=ids.length;i<max;i++){
			let id = ids[i]
			if(id === undefined) continue
			this.forEach((item, index)=>{
				if(item.id == id) {
					label = label + joinStr + item.label
					return false
				}
			})
		}
		if(label) 
			return label.replace(joinStr, '')
		else return ''
	}
})
Object.defineProperty(Object.prototype, 'clone', {
	configurable: true,
	enumerable: false,
	writable: true,
	value: function(obj) {
		return Object.assign(obj || {}, this)
	}
})

export default {
	install: (app, options) => {
		
		//应用信息
		app.config.globalProperties._app = { 
			name: 'CTX_MCH',
			appId: 0, 		//应用Id
			sign: ''		//应用签名
		};
		//用户信息
		app.config.globalProperties._user = {
			accessToken: '', //浏览凭证
			userToken: ''	//用户凭证
		};
		//接口配置
		app.config.globalProperties._api = { 
			//域名
			// domain: 'http://open.ctx.com/Mch',
			domain: 'https://open.chetongxing.net/Mch',
			//全局网络交互 Loading
			loading: 0,
			loading_timer: 0
		};
		//统计类
		app.config.globalProperties._count = { 
			vue: 0		//合计浏览vue界面数
		};



		//显示 Loading
		app.config.globalProperties._showLoding = function(config){
			if(!config) config = {};
			//Loding 加载
			this._api.loading = this.$loading({lock: true, text: '请稍后', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.5)'});
			//去掉定时任务
			if(this._api.loading_timer) clearTimeout(this._api.loading_timer);
			//定时关闭
			setTimeout(()=>{
				if(this._api.loading) this._api.loading.close();
			}, 15000);
		}

		//关闭 Loding（即时）
		app.config.globalProperties._hideLoding = function(){
			if(this._api.loading_timer) clearTimeout(this._api.loading_timer);
			if(this._api.loading) this._api.loading.close();
			this._api.loading = null;
		}
		
		//切换可见性模块
		app.config.globalProperties._changeVisible = function(root, visible, params){
			root.visible = visible
			if(params) root.params = params
		}
		
		//点击跳转
		app.config.globalProperties._navigateTo = function(e){
			this.$router.push(e.currentTarget.dataset.url);
		}
		
		//系统载入
		app.config.globalProperties._load = function(page, module, config){
			this._count.vue = this._count.vue + 1
			page._countVue = this._count.vue
			module._load(page, this, config)
		}
		
		//数据请求
		app.config.globalProperties._post = function(url, data, call, other){
		    if(!other) other = {};
		    if(other['loading'] !== false) this._showLoding();
			
			//POST配置
			let post = {
				url: this._api.domain + url,
				data:  data,
				method: 'post',
				headers: {
					'userToken': this._user.userToken,
					'accessToken': this._user.accessToken,
					'Content-Type': 'application/json;charset=utf-8',
					'Access-Control-Allow-Origin-Type': '*'
				}
			}
			//Request自定义配置
			if(other.request) {
				for(let rName in other.request) 
					post[rName] = other.request[rName]
			}
			
		    //提交交互
			this.$axios(post).then(
		        (res)=>{
					if(other['loading'] !== false) this._hideLoding();
					if(other['complete']) other['complete'](res);
		            if(other['return']) return call(res.data);
		            else
		            if(res.status == 200){
		                switch(res.data.code){
		                    case 0: call(res.data); break;
		                    case 300:
		                        this.$alert('请登录后使用哦~', '提示', {
		                            confirmButtonText: '确定',
		                            callback: () => {
		                                this.$router.push('/system/login');
		                            }
		                        });
		                    break;
							case 301:
								this.$router.push('/system/invalid');
							break;
		                    default:
		                        console.log('Http Error(1): SytemCode=', res.data.code, res.data);
								if(other['failed']) other['failed'](res);
								else
		                        this._msg(res.data.msg);
		                    break;
		                }
		            }else {
		                console.log('Http Error(2): httpcode=', res.status);
		                this.$alert('网络异常,请稍后重试', '提示', { confirmButtonText: '我知道了'});
		            }
		        },
		        (error)=>{
		            console.log('Http Error(3): ', error);
		            if(other['loading'] !== false) this._hideLoding();
					if(other['error']) other['error'](error);
					else
		            this.$alert('网络异常,请稍后重试', '提示', { confirmButtonText: '我知道了'});
		        }
		    )
		}
		
		//信息提示
		app.config.globalProperties._msg = function(content, timeout, call){
			if(!content) content = '操作成功';
			if(!timeout) timeout = 3000;
			this.$message({
				type: 'msg',
				message: content,
				duration: timeout,
				showClose: true,
				center: true,
				offset: 30,
				onClose: ()=>{
					if(call) call()
				}
			});
		}
		
		//信息提示（关闭弹窗）
		app.config.globalProperties._closebox = function(content, timeout, call){
			if(this.view.DrawerVisible!==undefined) this.view.DrawerVisible=false;
			this._msg(content, timeout, call);
		}
		
		//信息弹窗
		app.config.globalProperties._confirm = function(text, okCall, cancelCall, title){
			if(!title) title = '提示'
			ElMessageBox.confirm(
				text,
				title,
				{
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				}
			)
			.then(() => {
				if(okCall) okCall()
			})
			.catch(() => {
				if(cancelCall) cancelCall()
			})
		}
		
		//关闭弹窗
		app.config.globalProperties._closeDrawer = function(type){
			switch(type){
				default: this.setting[type] = false; break;
				case 'add': this.setting.addVisible = false; this._listSearch(); break;
				case 'edit': this.setting.editVisible = false; this._listSearch(); break;
			}
		}
		
		//打开弹窗
		app.config.globalProperties._openDialog = function(key, params){
			if(!this[key]) return false
			this[key].visible = true
			if(params) this[key].params = params
			if(this[key].open) this[key].open(params)
		}
		
		//关闭弹窗
		app.config.globalProperties._closeDialog = function(key){
			if(!this[key]) return false
			if(this[key].close) {
				if(this[key].close()) this[key].visible = false
			}else {
				this[key].visible = false
			}
		}
		
		//下载文件
		app.config.globalProperties._download = function(url, data, call, fileName){
			
			this._confirm('是否下载当前文件？', ()=>{
				if(!call) 
				call = (res)=>{
					let url = window.URL.createObjectURL(new Blob([res]))
					let link = document.createElement('a')
					link.style.display = 'none'
					link.href = url
					if(!fileName) fileName = 'file'
					link.setAttribute('download', fileName)
					document.body.appendChild(link)
					link.click()
				}
				this._post(url, data, call, {
					return: true,
					request: {
						responseType: 'blob'
					},
				})
			})
		}
		
		//初始化用户数据
		app.config.globalProperties._loadLoginUser = function(){
			if(!this._user.accessToken) {
				let res = localStorage.getItem(this._app.name +'_'+ this._app.appId)
				if(res) {
					res = JSON.parse(res)
					this._user.accessToken = res['accessToken']
					this._user.userToken   = res['userToken']
					this._user.mobile   = res['mobile']
					this._user.name     = res['name']
					this._user.roleId   = res['roleId']
					this._user.webAuths = res['webAuths']
					
				}
			}
			if(!this._user.accessToken) {
				if(this.$router) {
					this.$router.push({ path: '/system/login'})	
				}
			}
		}
		app.config.globalProperties._loadLoginUser()
		
		
	}
}