import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//创建应用
var app = createApp(App)

//导入axios
import axios from 'axios'
app.config.globalProperties.$axios = axios

//导入ElementUI
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import { Search, Plus, Edit, Delete, Upload, Loading, Refresh, Download } from '@element-plus/icons';
app.component('icSearch', Search);
app.component('icPlus', Plus);
app.component('icDelete', Delete);
app.component('icEdit', Edit);
app.component('icUpload', Upload);
app.component('icRefresh', Refresh);
app.component('icLoading', Loading);
app.component('icDownload', Download);

// import * as ElIcons from '@element-plus/icons'
// for(let name in ElIcons) app.component('ic' + name, ElIcons[name])

//导入系统初始化文件
import './assets/css/mn.css'
import './assets/css/custom.css'
import mn from './assets/js/mn'

import VueUeditorWrap from 'vue-ueditor-wrap'
app.use(VueUeditorWrap)

//定义window app对象
declare global { interface Window { vueApp: any;}}
window.vueApp = app

app
	.use(store)
	.use(mn)
	.use(router)
	.use(ElementPlus, {locale: zhCn})
	.mount('#app')
