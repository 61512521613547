/**
 * 项目路由列表
 */
export default
    {
		
		//首页
		'index': {
			name: 'index',
			title: '菜单',
			collapse: true,
			openeds: [ 'ServiceOrderGroup', 'ServiceJoinGroup', 'SystemUserGroup','ServiceAccountGroup','ServiceCouponGroup' ],
			children:[
				{ name: 'SystemWelcome', title: '欢迎页', path: '/system/welcome', icon: '-icselectionfill', System: true, hide: true},
				{ name: 'SystemInvalid', title: '无权限', path: '/system/invalid', icon: '-icselectionfill', hide: true},
				{ name: 'ServiceOrderGroup', title: '服务订单', icon: '-iccopy', children:
					[
						{ name: 'OrderList', title: '订单记录', path: '/order/list',},
						{ name: 'OrderUsed', title: '核销记录', path: '/order/used',},
						{ name: 'OrderApprove', title: '核销认证', path: '/order/approve',},
						{ name: 'OrderCodes', title: '我的核销码', path: '/order/codes',},
					]
				},
				{ name: 'ServiceJoinGroup', title: '服务接入', icon: '-icalbum', children:
					[
						{ name: 'JoinInfo', title: '接入信息', path: '/service/index',},
					]
				},
				{ name: 'ServiceAccountGroup', title: '服务结算管理', icon: '-icrefund', children:
					[
						{ name: 'AccountBill', title: '账单记录', path: '/serviceAccount/bill',},
						{ name: 'AccountFinal', title: '结算单', path: '/serviceAccount/final',},
					]
				},
				{ name: 'InsertAccountGroup', title: '接入结算管理', icon: '-icrefund', children:
					[
						{ name: 'insertAccountBill', title: '账单记录', path: '/insertAccount/bill',},
						{ name: 'insertAccountFinal', title: '结算单', path: '/insertAccount/final',},
					]
				},
				{ name: 'ServiceCouponGroup', title: '营销管理', icon: '-icalbum', children:
					[
						// { name: 'CouponClass', title: '类别设置', path: '/coupon/couponclass',},
						{ name: 'CouponList', title: '电子券码', path: '/coupon/list',},
						{ name: 'CouponUsed', title: '使用记录', path: '/coupon/used',},
					]
				},
				{ name: 'SystemUserGroup', title: '基础设置', icon: '-icapps', children:
					[
						{ name: 'SystemRole', title: '角色设置', path: '/system/role/list',},
						{ name: 'SystemUser', title: '账号设置', path: '/system/user/list',},
						{ name: 'SystemUserModityPwd', title: '修改密码', path: '/system/user/modifyPwd', System: true,},
						{ name: 'SystemUserLoginOut', title: '退出登录', System: true, call: function(page, item) {
							page._confirm('是否退出当前登录账户？', ()=>{
								page._post('/System/User/loginOut', {}, (res)=>{
									page._user.userToken = null
									page._user.accessToken = null
									localStorage.removeItem(page._app.name +'_'+ page._app.appId)
									window.location.reload()
								})
							})
						},},
					]
				},
			]
		},//End Index Module
		
		
		
		
		
		
		
		
    };
